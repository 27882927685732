import { useStaticQuery, graphql } from "gatsby"
export const useShopifyPlusQuery = () => {
  const data = useStaticQuery(
    graphql`
      query {
        allSanityShopifyPlus {
          edges {
            node {

              pageTitle
              metadescription
              heroTitle
              heroSubtitle
              mainTitle
              _rawMainText
              mainBtn
              
              timelineHeading
              timelineLastHeading
              _rawTimelineLastText
              timelineBtn

              timelineTitle1
              timelineTitle2
              timelineTitle3
              timelineTitle4
              timelineTitle5
              timelineTitle6
              timelineTitle7
              timelineTitle8
              timelineTitle9
              timelineTitle10
              timelineTitle11

              _rawTimelineText1
              _rawTimelineText2
              _rawTimelineText3
              _rawTimelineText4
              _rawTimelineText5
              _rawTimelineText6
              _rawTimelineText7
              _rawTimelineText8
              _rawTimelineText9
              _rawTimelineText10
              _rawTimelineText11

              orangeCtaTitle
              _rawOrangeCtaText
              orangeCtaBtn

              heroImage {
                asset {
                  fluid {
                    ...GatsbySanityImageFluid
                  }
                }
              }

              timelineIcon1 {
                asset {
                  fluid {
                    ...GatsbySanityImageFluid
                  }
                }
              }
              timelineIcon2 {
                asset {
                  fluid {
                    ...GatsbySanityImageFluid
                  }
                }
              }
              timelineIcon3 {
                asset {
                  fluid {
                    ...GatsbySanityImageFluid
                  }
                }
              }
              timelineIcon4 {
                asset {
                  fluid {
                    ...GatsbySanityImageFluid
                  }
                }
              }
              timelineIcon5 {
                asset {
                  fluid {
                    ...GatsbySanityImageFluid
                  }
                }
              }
              timelineIcon6 {
                asset {
                  fluid {
                    ...GatsbySanityImageFluid
                  }
                }
              }
              timelineIcon7 {
                asset {
                  fluid {
                    ...GatsbySanityImageFluid
                  }
                }
              }
              timelineIcon8 {
                asset {
                  fluid {
                    ...GatsbySanityImageFluid
                  }
                }
              }
              timelineIcon9 {
                asset {
                  fluid {
                    ...GatsbySanityImageFluid
                  }
                }
              }
              timelineIcon10 {
                asset {
                  fluid {
                    ...GatsbySanityImageFluid
                  }
                }
              }
              timelineIcon11 {
                asset {
                  fluid {
                    ...GatsbySanityImageFluid
                  }
                }
              }
              orangeCtaOverlayImg {
                asset {
                  fluid {
                    ...GatsbySanityImageFluid
                  }
                }
              }
            }
          }
        }
      }
    `
  )
  return data
}
