import React from "react"
import PortableText from "@sanity/block-content-to-react"
import "../styles/timeline.css"
import { FaCheckCircle } from "react-icons/fa"
import Button from "./Button"

class Timeline11 extends React.Component {
  render() {
    const { heading, bottomHeading, bottomText, bottomBtn } = this.props
    const {
      title1,
      title2,
      title3,
      title4,
      title5,
      title6,
      title7,
      title8,
      title9,
      title10,
      title11,
    } = this.props
    const {
      text1,
      text2,
      text3,
      text4,
      text5,
      text6,
      text7,
      text8,
      text9,
      text10,
      text11,
    } = this.props
    const {
      icon1,
      icon2,
      icon3,
      icon4,
      icon5,
      icon6,
      icon7,
      icon8,
      icon9,
      icon10,
      icon11,
    } = this.props
    return (
      <div className="py-25" style={{ background: "#f2f2f2" }}>
        <div className="max-w-twelve mx-auto flex flex-col font-hairline text-center timeline mb-6">
          <div>
            <h2 className="text-2xl">{heading}</h2>
            <div className="w-48 border border-2 border-green mt-5 lg:mb-20 mx-auto"></div>
          </div>
          <div>
            <div className="w-full flex flex-col lg:flex-row">
              <div className="flex-1 lg:p-20 px-5 py-20 flex justify-center items-center flex-col">
                <img src={icon1} alt={title1} className="w-32 mx-auto mb-6" />
                <h3 className="font-medium text-xl -mb-10 lg:-mb-0 lg:mb-6">
                  {title1}
                </h3>
              </div>
              <div className="markbox">
                <div className="mark">
                  <div className="symbol upper">
                    <FaCheckCircle size="48px" />
                  </div>
                </div>
              </div>
              <div className="flex-1 lg:p-20 px-5 pb-8 pt-12 bg-white border-l-4 border-orange flex items-center">
                <PortableText blocks={text1} />
              </div>
            </div>
            <div className="w-full flex flex-col lg:flex-row-reverse">
              <div className="flex-1 p-20 flex justify-center items-center flex-col">
                <img src={icon2} alt={title2} className="w-32 mx-auto mb-6" />
                <h3 className="font-medium text-xl -mb-10 lg:-mb-0 lg:mb-6">
                  {title2}
                </h3>
              </div>
              <div className="markbox">
                <div className="mark">
                  <div className="symbol lower">
                    <FaCheckCircle size="48px" />
                  </div>
                </div>
              </div>
              <div className="flex-1 lg:p-20 px-5 pb-8 pt-12 bg-white border-r-4 border-orange flex items-center">
                <PortableText blocks={text2} />
              </div>
            </div>
            <div className="w-full flex flex-col lg:flex-row">
              <div className="flex-1 lg:p-20 px-5 py-20 flex justify-center items-center flex-col">
                <img src={icon3} alt={title3} className="w-32 mx-auto mb-6" />
                <h3 className="font-medium text-xl -mb-10 lg:-mb-0 lg:mb-6">
                  {title3}
                </h3>
              </div>
              <div className="markbox">
                <div className="mark">
                  <div className="symbol upper">
                    <FaCheckCircle size="48px" />
                  </div>
                </div>
              </div>
              <div className="flex-1 lg:p-20 px-5 pb-8 pt-12 bg-white border-l-4 border-orange flex items-center">
                <PortableText blocks={text3} />
              </div>
            </div>
            <div className="w-full flex flex-col lg:flex-row-reverse">
              <div className="flex-1 p-20 flex justify-center items-center flex-col">
                <img src={icon4} alt={title4} className="w-32 mx-auto mb-6" />
                <h3 className="font-medium text-xl -mb-10 lg:-mb-0 lg:mb-6">
                  {title4}
                </h3>
              </div>
              <div className="markbox">
                <div className="mark">
                  <div className="symbol lower">
                    <FaCheckCircle size="48px" />
                  </div>
                </div>
              </div>
              <div className="flex-1 lg:p-20 px-5 pb-8 pt-12 bg-white border-r-4 border-orange flex items-center">
                <PortableText blocks={text4} />
              </div>
            </div>
            <div className="w-full flex flex-col lg:flex-row">
              <div className="flex-1 lg:p-20 px-5 py-20 flex justify-center items-center flex-col">
                <img src={icon5} alt={title5} className="w-32 mx-auto mb-6" />
                <h3 className="font-medium text-xl -mb-10 lg:-mb-0 lg:mb-6">
                  {title5}
                </h3>
              </div>
              <div className="markbox">
                <div className="mark">
                  <div className="symbol upper">
                    <FaCheckCircle size="48px" />
                  </div>
                </div>
              </div>
              <div className="flex-1 lg:p-20 px-5 pb-8 pt-12 bg-white border-l-4 border-orange flex items-center">
                <PortableText blocks={text5} />
              </div>
            </div>
            <div className="w-full flex flex-col lg:flex-row-reverse">
              <div className="flex-1 p-20 flex justify-center items-center flex-col">
                <img src={icon6} alt={title6} className="w-32 mx-auto mb-6" />
                <h3 className="font-medium text-xl -mb-10 lg:-mb-0 lg:mb-6">
                  {title6}
                </h3>
              </div>
              <div className="markbox">
                <div className="mark">
                  <div className="symbol lower">
                    <FaCheckCircle size="48px" />
                  </div>
                </div>
              </div>
              <div className="flex-1 lg:p-20 px-5 pb-8 pt-12 bg-white border-r-4 border-orange flex items-center">
                <PortableText blocks={text6} />
              </div>
            </div>
            <div className="w-full flex flex-col lg:flex-row">
              <div className="flex-1 lg:p-20 px-5 py-20 flex justify-center items-center flex-col">
                <img src={icon7} alt={title7} className="w-32 mx-auto mb-6" />
                <h3 className="font-medium text-xl -mb-10 lg:-mb-0 lg:mb-6">
                  {title7}
                </h3>
              </div>
              <div className="markbox">
                <div className="mark">
                  <div className="symbol upper">
                    <FaCheckCircle size="48px" />
                  </div>
                </div>
              </div>
              <div className="flex-1 lg:p-20 px-5 pb-8 pt-12 bg-white border-l-4 border-orange flex items-center">
                <PortableText blocks={text7} />
              </div>
            </div>
            <div className="w-full flex flex-col lg:flex-row-reverse">
              <div className="flex-1 p-20 flex justify-center items-center flex-col">
                <img src={icon8} alt={title8} className="w-32 mx-auto mb-6" />
                <h3 className="font-medium text-xl -mb-10 lg:-mb-0 lg:mb-6">
                  {title8}
                </h3>
              </div>
              <div className="markbox">
                <div className="mark">
                  <div className="symbol lower">
                    <FaCheckCircle size="48px" />
                  </div>
                </div>
              </div>
              <div className="flex-1 lg:p-20 px-5 pb-8 pt-12 bg-white border-r-4 border-orange flex items-center">
                <PortableText blocks={text8} />
              </div>
            </div>
            <div className="w-full flex flex-col lg:flex-row">
              <div className="flex-1 lg:p-20 px-5 py-20 flex justify-center items-center flex-col">
                <img src={icon9} alt={title9} className="w-32 mx-auto mb-6" />
                <h3 className="font-medium text-xl -mb-10 lg:-mb-0 lg:mb-6">
                  {title9}
                </h3>
              </div>
              <div className="markbox">
                <div className="mark">
                  <div className="symbol upper">
                    <FaCheckCircle size="48px" />
                  </div>
                </div>
              </div>
              <div className="flex-1 lg:p-20 px-5 pb-8 pt-12 bg-white border-l-4 border-orange flex items-center">
                <PortableText blocks={text9} />
              </div>
            </div>
            <div className="w-full flex flex-col lg:flex-row-reverse">
              <div className="flex-1 p-20 flex justify-center items-center flex-col">
                <img src={icon10} alt={title10} className="w-32 mx-auto mb-6" />
                <h3 className="font-medium text-xl -mb-10 lg:-mb-0 lg:mb-6">
                  {title10}
                </h3>
              </div>
              <div className="markbox">
                <div className="mark">
                  <div className="symbol lower">
                    <FaCheckCircle size="48px" />
                  </div>
                </div>
              </div>
              <div className="flex-1 lg:p-20 px-5 pb-8 pt-12 bg-white border-r-4 border-orange flex items-center">
                <PortableText blocks={text10} />
              </div>
            </div>
            <div className="w-full flex flex-col lg:flex-row">
              <div className="flex-1 lg:p-20 px-5 py-20 flex justify-center items-center flex-col">
                <img src={icon11} alt={title11} className="w-32 mx-auto mb-6" />
                <h3 className="font-medium text-xl -mb-10 lg:-mb-0 lg:mb-6">
                  {title11}
                </h3>
              </div>
              <div className="markbox">
                <div className="mark">
                  <div className="symbol upper">
                    <FaCheckCircle size="48px" />
                  </div>
                </div>
              </div>
              <div className="flex-1 lg:p-20 px-5 pb-8 pt-12 bg-white border-l-4 border-orange flex items-center">
                <PortableText blocks={text11} />
              </div>
            </div>
          </div>
          <div>
            <h2 className="mx-auto mt-20 text-xl leading-normal" style={{ maxWidth: "700px" }}>{bottomHeading}</h2>
            <div className="w-56 border-b-2 border-green mt-6 mx-auto" ></div>
            <p
              className="mt-5 mx-auto"
              style={{ maxWidth: "700px", lineHeight: "1.5" }}
            >
              <PortableText blocks={bottomText} />
            </p>

            <div
              className="mx-auto"
              style={{ marginTop: "50px", maxWidth: "280px" }}
            >
              <Button btnText={bottomBtn} bgColor="bg-green" slug="/shopify-plus/#contact" />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Timeline11
