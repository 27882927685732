import React from "react"
import Button from "./Button"
import PortableText from "@sanity/block-content-to-react"

class MainSimple extends React.Component {
  render() {

    const { mainBtn, mainTitle, mainText, slug } = this.props

    return (
      <div className="my-25 mx-auto font-hairline text-dark">
        <div className="max-w-twelve flex flex-col mx-auto text-center lg:text-left lg:flex-row">
          {/* LEFT CONTAINER */}
          <div
            className="flex flex-col"
            style={{ padding: "10px", marginRight: "30px" }}
          >
            <h2 className="mb-6 text-2xl">{mainTitle}</h2>
            <div className="w-48 border border-2 border-green mb-5"></div>
            <PortableText blocks={mainText} />
          </div>
        </div>
        <div className="mx-auto md:p-0 px-5" style={{ marginTop: "45px", maxWidth: "310px" }}>
          <Button btnText={mainBtn} bgColor="bg-green" slug={slug} />
        </div>
      </div>
    )
  }
}

export default MainSimple