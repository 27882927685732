import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Brands from "../components/Brands"
import Contact from "../components/Contact"
import HeroSimple from "../components/HeroSimple"
import Timeline11 from "../components/Timeline11"
import MainSimple from "../components/MainSimple"
import OrangeCTA from "../components/OrangeCTA"
import { useShopifyPlusQuery } from "../hooks/useShopifyPlusQuery"
import YearsInBusiness from "../components/YearsInBusiness"

const ShopifyPlusPage = () => {
  const data = useShopifyPlusQuery()
  const short = data.allSanityShopifyPlus.edges[0].node
  return (
    <Layout>
      <SEO title="Shopify Plus" />
      <HeroSimple
        heroImage={short.heroImage.asset.fluid}
        heroTitle={short.heroTitle}
        heroSubtitle={short.heroSubtitle}
      />
      <MainSimple
        mainTitle={short.mainTitle}
        mainText={short._rawMainText}
        mainBtn={short.mainBtn}
        slug="/shopify-support/#contact"
      />

      <Timeline11
        heading={short.timelineHeading}
        bottomText={short._rawTimelineLastText}
        bottomBtn={short.timelineBtn}
        bottomHeading={short.timelineLastHeading}
        title1={short.timelineTitle1}
        title2={short.timelineTitle2}
        title3={short.timelineTitle3}
        title4={short.timelineTitle4}
        title5={short.timelineTitle5}
        title6={short.timelineTitle6}
        title7={short.timelineTitle7}
        title8={short.timelineTitle8}
        title9={short.timelineTitle9}
        title10={short.timelineTitle10}
        title11={short.timelineTitle11}
        text1={short._rawTimelineText1}
        text2={short._rawTimelineText2}
        text3={short._rawTimelineText3}
        text4={short._rawTimelineText4}
        text5={short._rawTimelineText5}
        text6={short._rawTimelineText6}
        text7={short._rawTimelineText7}
        text8={short._rawTimelineText8}
        text9={short._rawTimelineText9}
        text10={short._rawTimelineText10}
        text11={short._rawTimelineText11}
        icon1={short.timelineIcon1.asset.fluid.src}
        icon2={short.timelineIcon2.asset.fluid.src}
        icon3={short.timelineIcon3.asset.fluid.src}
        icon4={short.timelineIcon4.asset.fluid.src}
        icon5={short.timelineIcon5.asset.fluid.src}
        icon6={short.timelineIcon6.asset.fluid.src}
        icon7={short.timelineIcon7.asset.fluid.src}
        icon8={short.timelineIcon8.asset.fluid.src}
        icon9={short.timelineIcon9.asset.fluid.src}
        icon10={short.timelineIcon10.asset.fluid.src}
        icon11={short.timelineIcon11.asset.fluid.src}
      />

      <OrangeCTA
        title={short.orangeCtaTitle}
        text={short._rawOrangeCtaText}
        btn={short.orangeCtaBtn}
        overlayImg={short.orangeCtaOverlayImg.asset.fluid}
      />
      <Brands />
      <YearsInBusiness />
      <Contact />
    </Layout>
  )
}

export default ShopifyPlusPage
